import './signup-styles.scss';
import SignupForm from '../../components/signup-form/signup-form.component';

const Signup = () => {
    return (
        <>
            <h1>Signup to continue</h1>
            <SignupForm />
        </>
    );
};

export default Signup;